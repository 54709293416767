import Vue from 'vue'
import sessionService from '@/services/SessionService'

const state = Vue.observable({
    token: null,
    refreshToken: null,
    tokenId: null,
    error: false,
})

const setTokenData = (data) => {
    state.token = data.token
    state.refreshToken = data.refresh_token
    state.session_state = data.session_state
    state.error = false
}

export default {
    // getters
    get state() {
        return state
    },

    get isSessionSet() {
        return state.token !== undefined;
    },

    // mutations
    async setSession() {
        if (!window.sessionStorage.getItem('login-required')) {
            const token = sessionService.getToken()
            const refreshToken = sessionService.getRefreshToken()

            if (token && refreshToken) {
                setTokenData({ token, refresh_token: refreshToken })
            } else {
                const newToken = await sessionService.getNewToken()
                newToken === null
                    ? (state.error = true)
                    : setTokenData(newToken)
            }
        }else{
            window.sessionStorage.removeItem('login-required')
        }
        
        return
    },

    async setNewSession() {
        const newToken = await sessionService.getNewToken()
        newToken === null ? (state.error = true) : setTokenData(newToken)

        return
    },

    //actions
    /* hasRoles(keycloak, routeRoles) {
        const roles = this.getRoles(keycloak)
        const routeRolesNumber = routeRoles.length
        var hasRole = false
        var index = 0

        while (hasRole === false && index < routeRolesNumber) {
            const searchRole = routeRoles[index]
            hasRole = roles.indexOf(searchRole) >= 0
            index += 1
        }

        return hasRole
    }, */
    hasRoles(keyCloak, roles = []) {
        const roleIndex = roles.findIndex(role => keyCloak.hasRealmRole(role));
        return roleIndex >= 0;
    },

    getRoles(keycloak) {
        if(!keycloak.resourceAccess.webapp){
            return keycloak.resourceAccess.account.roles || []
        }
        return keycloak.resourceAccess.webapp.roles || []
    },
}
