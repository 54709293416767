import Vue from 'vue'
import profileService from '@/services/ProfileService'
import deviceId from '@/domain/DeviceId'

const state = Vue.observable({
    users: [],
    pagination: {
        page: 0,
        totalPages: 0,
        totalItems: 0,
        itemsPerPage: 10
    }
});

export default {
    // make state a getter here to provide access to the private state variable
    // only allow getting so that it cannot be modified without calling a mutation method
    get state(){ return state },
    get users(){ return state.users},
    get pagination() { return state.pagination },

    // mutations
    async setUsers(page = 1, filterOptions = null) {
        const users = await profileService.getUsers(page, state.pagination.itemsPerPage, filterOptions);
        state.users = users.profile;
        state.pagination.page = parseInt(users.pagination.page);
        state.pagination.totalPages = parseInt(users.pagination.pages);
        state.pagination.totalItems = users.pagination.total
        return;
    },

    async updateItemsPerPage(items, filterOptions = null) {
        state.pagination.itemsPerPage = items;
        return await this.setUsers(1, filterOptions);
    },

    async update(newData, oldData, id) {
        return await profileService.updateProfile(newData, oldData, id);
    },

    //actions
    async getUser(id) {
        return await profileService.getUser(id);
    },

    isAdmin(keyCloak) {
        return keyCloak.hasRealmRole('admin');
    },

    hasRoleFromList(keyCloak, roles = []) {
        const roleIndex = roles.findIndex(role => keyCloak.hasRealmRole(role));
        return roleIndex >= 0;
    },

    logout(keyCloak) {
        keyCloak.logout();
    },

    async saveDeviceId(userId){ //for push notifications
        try {
            if(!deviceId.getDeviceId()){
                const did = deviceId.generateDeviceId();
                await profileService.saveUserDeviceId(userId, did);
            }
        } catch (error) {
            deviceId.removeDeviceID();
        }
    },
}