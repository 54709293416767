import axios from 'axios';
// import router from '@/router';
import { keycloak } from '@/plugins/keycloak';

const url = process.env.VUE_APP_API_URL

const api = axios.create({
    baseURL: `${url}`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(async (config) => {
    await keycloak.updateTokenIfExpired();
    const refreshToken = sessionStorage.getItem('vue-token');

    if(refreshToken){
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${ refreshToken }`
        }
    }
    return config;

    }, (error) => {
      return Promise.reject(error);
    }
);

export default api;
