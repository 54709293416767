import api from '@/services/Api'
import { DateTime } from 'luxon';
import QueryBuilder from '@/domain/QueryBuilder';

const millisToDate = (millis, format = 'yyyy-MM-dd') => {
    return DateTime.fromMillis(millis).toFormat(format);
}

const dateToMillis = (millis, format = 'yyyy-MM-dd') => {
    return DateTime.fromFormat(millis, format).toMillis();
}

export default {
    async getUsers(page = 1, limit = 5, filterOptions = null){
        const query = QueryBuilder.buildQuery({ page, limit, ...filterOptions });

        const response = await api.get(`/profile${query}`);
        return response.data.data;
    },

    async getUser(userId) {
        const response = await api.get(`/profile/${userId}`);
        const profile = response.data.data.profile;

        profile.birthday = profile.birthday !== undefined ? millisToDate(profile.birthday) : '';
        profile.tax = profile.tax?.toString() || '';
        return profile;       
    },

    async updateProfile(profile, oldData) {
        const id = profile.id;
        const newProfileUser = {};
        
        const profileProps = Object.keys(profile);
        
        profileProps.forEach(prop => {
            if(profile[prop] !== oldData[prop]){
                newProfileUser[prop] = profile[prop]
            }
        });

        if(newProfileUser.tax)
            newProfileUser.tax = parseInt(newProfileUser.tax || 0);

        if(newProfileUser.phone)
            newProfileUser.phone = parseInt(newProfileUser.phone || 0);

        if(newProfileUser.birthday)
            newProfileUser.birthday = dateToMillis(newProfileUser.birthday);

        const response = await api.patch(`/profile/${id}`, newProfileUser);
        
        const profileUpdated = response.data.data;
        profileUpdated.tax = profileUpdated.tax.toString();
        profileUpdated.birthday = millisToDate(profileUpdated.birthday);

        return profileUpdated;
    },

    async saveUserDeviceId(userId, identifier){
        return await api.post('/profile/devices', { userId, identifier: identifier.toString() });
    }
}
