<template>
  <v-list-item
	link
	active-class="active-list"
    :to="to"
  >
   <v-list-item-icon v-if="icon">
     <v-icon>{{icon}}</v-icon>
   </v-list-item-icon>

    <v-list-item-content v-if="text">
      <v-list-item-title>{{text}}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
	props: {
		to: {
			type: [String, Object],
			required: true,
		},

		icon: {
			type: String,
			default: null,
		},

		text: {
			type: String,
			default: null,
		}
	}
}
</script>
<style scoped>
.active-list{
	background: #008167;
	color: white;
}
</style>