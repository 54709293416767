import api from '@/services/Api'

import { DeviceUUID } from "device-uuid";

export default {
    async getNewToken() {
        try {
            const deviceID = new DeviceUUID().get();
            const response = await api.post(`/session`, { deviceID });
            const credentials = response.data.data;

            sessionStorage.setItem('DEV_SESSION', deviceID);
            sessionStorage.setItem('vue-token', credentials.token);
            sessionStorage.setItem('vue-refresh-token', credentials.refresh_token);
            
            return response.data.data;       
        } catch (error) {
            return null;
        }
    },
    
    getToken() {
        return sessionStorage.getItem('vue-token');
    },

    getRefreshToken() {
        return sessionStorage.getItem('vue-refresh-token');
    }
}
