<template>
  <v-app>
    <app-header v-model="showDrawer" />
    <app-drawer v-model="showDrawer" />
    <v-main>
      <router-view />
    </v-main>
    <v-snackbar
      v-model="notification.show"
      color="success"
      right
      top
      :timeout="10000"
    >
      <div class="white--text">
        <div><b>{{ notification.title }}</b></div>
        <v-divider class="my-2" />
        <div>{{ notification.body }}</div>
      </div>
    </v-snackbar> 
    <v-snackbar
      v-model="internalNotification.show"
      :color="alertData.kind"
      right
      top
      :timeout="6000"
    >
      <div class="white--text">
        <div><b>{{ alertData.title }}</b></div>
        <v-divider class="my-2" />
        <div>{{ alertData.message }}</div>
      </div>
    </v-snackbar> 
    <div
      class=""
    >
      s
    </div>
  </v-app>
</template>

<script>
import AppHeader from '@/layout/Header';
import AppDrawer from '@/layout/Drawer';
import { onNotification, messaging, token } from './plugins/firebase';
import { alert } from './store/alerts';
import { watch } from 'vue';


export default {
  name: 'App',

  components: {
    AppHeader,
    AppDrawer,
  },

  data() {
    return {
      showDrawer: true,

      notification: {
        show: false,
        title: '',
        body: ''
      },
      internalNotification:{
        show: false,
        title: '',
        message: '',
      }
    }
  },
  computed: {
    alertData() {
      return alert.alert
    },
   
  },
  watch:{
    alertData: {
      handler: function(){
        this.internalNotification.show = false;
        this.internalNotification.show = true;
      },
      deep: true
    }
  },

  async mounted(){
    console.log('getting token', await token)
    onNotification(messaging, ({ notification }) => {
      this.notification.title = notification.title;
      this.notification.body = notification.body;
      this.notification.show = true;
    });

  }

};
</script>
<link href="https://fonts.googleapis.com/css?family=Material+Icons" rel="stylesheet" />

<style src="./assets/global.css" />
