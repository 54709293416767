import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG) ;

export const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

export const token = getToken(messaging, {vapidKey: "BGlpB5H7QvtJjJXZUD11-heQskamca_6gLWfai3cWL9bscOhFAu8Im7VtLAZ3SxNu1Y9X5f8ai2vPyFpQuT-R90"});

export const requestPermission = () => {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    }
  })
}

export const onNotification = onMessage;