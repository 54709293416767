class ParamBuilder {
  buildQuery(props, startCharter = '?') {
    if (!props) return "";

    const keys = Object.keys(props);

    const query = keys.reduce((prev, next) => {
			if(props[next]){
				return (prev += next + '=' + props[next]) + '&';
			} else {
				return prev
			}
    }, "");

    return startCharter + query.slice(0, query.length - 1);
  }
}

export default new ParamBuilder();