import { ClientJS } from 'clientjs';

class DeviceId {
  generateDeviceId(){
    let machineId = localStorage.getItem('MachineId');
    
    if (!machineId) {
      const client = new ClientJS();
      machineId = client.getFingerprint();
      localStorage.setItem('MachineId', machineId);
    }

    return machineId;
  }

  getDeviceId(){
    const id = localStorage.getItem('MachineId');
    return id;
  }

  removeDeviceID(){
    localStorage.removeItem('MachineId', '');
  }
}

export default new DeviceId();