<template>
  <v-app-bar
    app
    color="white"
    dense
    flat
  >
    <v-row
      no-gutters
      align="center"
    >
      <v-app-bar-nav-icon @click.stop="showDrawer = !showDrawer" />
      <div
        v-if="routeDisplayName"
        class="text-h5 font-weight-light"
      >
        {{ routeDisplayName }}
      </div>

      <v-spacer />
      <user-menu />
    </v-row>
  </v-app-bar>
</template>

<script>
import UserMenu from './UserMenu.vue';

export default {
    name: 'AppHeader',

    components: {
        UserMenu,
    },

    props: {
        value: {
            type: Boolean,
            required: true,
        }
    },

    computed: {
        routeDisplayName() {
            return this.$route.meta?.displayName || null;
        },

        showDrawer: {
            set(newValue){
                this.$emit('input', newValue);
            },

            get(){
                return this.value
            }
        }
    }
}
</script>