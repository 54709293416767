import Vue from 'vue'
import VueRouter from 'vue-router'
import userStore from '@/store/user';
import { cardRoles, transactionRoles, placeEventsRoles } from '@/middleware/moduleRoles';
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      displayName: 'Dashboard'
    }
  },
  {
    path: '/company',
    name: 'company',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Company.vue'),
    meta: {
      displayName: 'Empresa'
    }
  },
  {
    path: '/places',
    name: 'places',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PlaceIndex.vue'),
    meta: {
      displayName: 'Lugares'
    }
  },
  {
    path: '/places/new',
    name: 'newPlace',
    component: () => import('../views/PlaceCreate.vue'),
    meta: {
      displayName: 'Nuevo Lugar'
    }
  },
  {
    path: '/places/edit/:id',
    name: 'editPlace',
    component: () => import('../views/PlaceEdit.vue'),
    meta: {
      displayName: 'Editar Lugar'
    }
  },
  {
    path: '/establishments',
    name: 'establishments',
    component: () => import(/* webpackChunkName: "about" */ '../views/EstablishmentIndex.vue'),
    meta: {
      displayName: 'Establecimientos'
    }
  },
  {
    path: '/establishments/new',
    name: 'newEstablishment',
    component: () => import('../views/EstablishmentCreate.vue'),
    meta: {
      displayName: 'Establecimientos'
    }
  },
  {
    path: '/establishments/edit/:id',
    name: 'editEstablishment',
    component: () => import('../views/EstablishmentEdit.vue'),
    meta: {
      displayName: 'Editar Establecimiento'
    }
  },
  {
    path: '/events/new',
    name: 'newEvent',
    component: () => import(/* webpackChunkName: "about" */ '../views/EventCreate.vue'),
    meta: {
      displayName: 'Eventos'
    }
  },
  {
    path: '/events/edit/:id',
    name: 'editEvent',
    component: () => import(/* webpackChunkName: "about" */ '../views/EventEdit.vue'),
    meta: {
      displayName: 'Eventos'
    }
  },
  {
    path: '/events',
    name: 'events',
    component: () => import(/* webpackChunkName: "about" */ '../views/EventIndex.vue'),
    meta: {
      displayName: 'Eventos'
    }
  },
  {
    path: '/places-events',
    name: 'eventsPlace',
    component: () => import(/* webpackChunkName: "about" */ '../views/EventPlaceIndex.vue'),
    meta: {
      displayName: 'Eventos de lugares',
      roles: placeEventsRoles,
    }
  },
  {
    path: '/places-events/new',
    name: 'newEventPlace',
    component: () => import(/* webpackChunkName: "about" */ '../views/EventPlaceCreate.vue'),
    meta: {
      displayName: 'Eventos de lugares',
      roles: placeEventsRoles,
    }
  },
  {
    path: '/places-events/edit/:id',
    name: 'editEventPlace',
    component: () => import(/* webpackChunkName: "about" */ '../views/EventPlaceEdit.vue'),
    meta: {
      displayName: 'Eventos de lugares',
      roles: placeEventsRoles,
    }
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import(/* webpackChunkName: "about" */ '../views/TicketIndex.vue'),
    meta: {
      displayName: 'Tickets de eventos'
    }
  },
  {
    path: '/tickets/new',
    name: 'newTicket',
    component: () => import(/* webpackChunkName: "about" */ '../views/TicketCreate.vue'),
    meta: {
      displayName: 'Tickets de eventos'
    }
  },
  {
    path: '/tickets/edit/:id',
    name: 'editTicket',
    component: () => import(/* webpackChunkName: "about" */ '../views/TicketEdit.vue'),
    meta: {
      displayName: 'Tickets de eventos'
    }
  },
  {
    path: '/services',
    name: 'services',
    component: () => import(/* webpackChunkName: "about" */ '../views/ServiceIndex.vue'),
    meta: {
      displayName: 'Servicios de establecimiento'
    }
  },
  {
    path: '/services/new',
    name: 'newService',
    component: () => import(/* webpackChunkName: "about" */ '../views/ServiceCreate.vue'),
    meta: {
      displayName: 'Servicios de establecimiento'
    }
  },
  {
    path: '/services/edit/:id',
    name: 'editService',
    component: () => import(/* webpackChunkName: "about" */ '../views/ServiceEdit.vue'),
    meta: {
      displayName: 'Servicios de establecimiento'
    }
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import(/* webpackChunkName: "about" */ '../views/CategoryIndex.vue'),
    meta: {
      displayName: 'Categorías'
    }
  },
  {
    path: '/types',
    name: 'types',
    component: () => import(/* webpackChunkName: "about" */ '../views/TypeIndex.vue'),
    meta: {
      displayName: 'Tipos'
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "about" */ '../views/UserIndex.vue'),
    meta: {
      displayName: 'Usuarios'
    }
  },
  {
    path: '/users/edit/:id',
    name: 'editUser',
    component: () => import('../views/UserEdit.vue'),
    meta: {
      displayName: 'Editar Usuario'
    }
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('../views/CardIndex.vue'),
    meta: {
      displayName: 'Tarjetas',
      roles: cardRoles,
    }
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('../views/TransactionIndex.vue'),
    meta: {
      displayName: 'Transacciones',
      roles: transactionRoles,
    }
  },
  {
    path: '/forbbiden',
    name: 'forbbiden',
    component: () => import('../views/Forbbiden.vue'),
    meta: {
      displayName: '',
    }
  },
  {
    path: '/*',
    name: 'notFound',
    component: () => import('../views/404.vue'),
    meta: {
      displayName: '',
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router


router.beforeEach(async (to, from, next) => {
  const roles = to.meta.roles || undefined;

  if( !roles || roles.length === 0 ) return next(); //every body can access to this route

  const hasRoleFromList = userStore.hasRoleFromList(Vue.$keycloak, roles);

  if(hasRoleFromList){
    next()
  } else {
    next({name: 'forbbiden'});
    //TODO: mandar a pantalla de permisos insuficientes
  }
})
