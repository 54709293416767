<template>
  <v-navigation-drawer
    v-model="drawerState"
    :mini-variant="miniBreakPoint"
    :permanent="permanent"
    app
  >
    <router-link to="/">
      <v-img
        width="100%"
        height="70px"
        contain
        src="../assets/images/logo.png"
      />
    </router-link>

    <v-divider color="white" />

    <v-list
      dense
      nav
    >
      <v-list-item-group>
        <list-item
          v-if="checkPermissions"
          to="/company"
          icon="mdi-account-circle"
          text="Empresa"
        />
        <list-item
          v-if="checkPermissions"
          to="/places"
          icon="mdi-map-marker"
          text="Lugares"
        />
        <list-item
          to="/establishments"
          icon="mdi-store-outline"
          text="Establecimientos"
        />
        <list-item
          to="/events"
          icon="mdi-party-popper"
          text="Eventos"
        />
        <list-item
          v-if="showModulePlaceEvents"
          to="/places-events"
          icon="mdi-dance-ballroom"
          text="Eventos Lugares"
        />
        <list-item
          to="/services"
          icon="mdi-room-service"
          text="Servicios Establecimiento"
        />
        <list-item
          to="/tickets"
          icon="mdi-ticket-confirmation"
          text="Tickets"
        />
        <list-item
        
          v-if="showModuleCards && !checkCompany"
          to="/cards"
          icon="mdi-credit-card-chip"
          text="Tarjetas"
        />
        <list-item
          v-if="showModuleTransactions"
          to="/transactions"
          icon="mdi-cash-sync"
          text="Transacciones"
        />
        <list-item
          v-if="!checkCompany"
          to="/users"
          icon="mdi-account-supervisor"
          text="Usuarios"
        />
        <list-item
          v-if="!checkCompany"
          to="/categories"
          icon="mdi-shape"
          text="Categorías"
        />
        <list-item
          v-if="!checkCompany"
          to="/types"
          icon="mdi-chart-bubble"
          text="Tipos"
        />
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import listItem from "@/components/ListItem.vue";
import userStore from '@/store/user';
import { cardRoles, transactionRoles, placeEventsRoles, adminCompanyRoles, companyRole } from '@/middleware/moduleRoles';

export default {
  name: "AppDrawer",

  components: {
    listItem,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    drawerState: {
      set(newValue) {
        this.$emit("input", newValue);
      },

      get() {
        return this.value;
      },
    },

    miniBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": return false;
        case "sm": return false;
        case "md": return !this.drawerState;
        case "lg": return !this.drawerState;
        case "xl": return !this.drawerState;
        default: return !this.drawerState;
      }
    },

    permanent() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": return false;
        case "sm": return false;
        case "md": return false;
        case "lg": return true;
        case "xl": return true;
        default: return true;
      }
    },

    showModuleCards() {
      return userStore.hasRoleFromList(this.$keycloak, cardRoles);
    },
    
    showModuleTransactions() {
      return userStore.hasRoleFromList(this.$keycloak, transactionRoles);
    },

    showModulePlaceEvents() {
      return userStore.hasRoleFromList(this.$keycloak, placeEventsRoles);
    },
    checkPermissions() {
      return userStore.hasRoleFromList(this.$keycloak, adminCompanyRoles);
    },
    checkCompany() {
      return userStore.hasRoleFromList(this.$keycloak, companyRole);
    }
  },
};
</script>
