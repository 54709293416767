import Vue from 'vue'
class Alerts{
    state = Vue.observable({ alert: {title:'',message:'',kind:'',dice:0} })

    get state(){ return this.state }
    get alert() { 
        return this.state.alert 
    }

    
    setAlert(alert) {
        this.state.alert.title = alert.title;
        this.state.alert.message = alert.message;
        this.state.alert.kind = alert.kind; 
        this.state.alert.dice = this.alert.dice+1;
        return
    }
}

export const alert = new Alerts();